import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Default',
    component: function () {
      return import(/* webpackChunkName: "dbfrontend" */ "../views/Kundenportal/Frontend/Dashboard.vue")
    },
    meta: {
      title: "Startseite"
    }
  },
  {
    path: '/dbfrontend',
    name: 'DBFrontend',
    component: function () {
      return import(/* webpackChunkName: "dbfrontend" */ "../views/Kundenportal/Frontend/Dashboard.vue")
    },
    meta: {
      requiresAuth: true,
      showInDrawer: false,
      title: 'Startseite',
    },
  },
  {
    path: '/selfdisclosure',
    name: 'SelfDisclosure',
    component: function () {
      return import(/* webpackChunkName: "selfdisclosure3" */ "../views/Kundenportal/Frontend/SelfDisclosure3.vue")
    },
    meta: {
      requiresAuth: true,
      showInDrawer: false,
      title: 'Selbstauskunft',
    },
  },
  {
    path: '/selfdisclosure3',
    name: 'SelfDisclosure3',
    component: function () {
      return import(/* webpackChunkName: "selfdisclosure" */ "../views/Kundenportal/Frontend/SelfDisclosure3.vue")
    },
    meta: {
      requiresAuth: true,
      showInDrawer: false,
      title: 'Selbstauskunft',
    },
  },
  {
    path: "/login",
    name: "Login",
    component: function () {
      return import(/* webpackChunkName: "login" */ "../views/login-view.vue")
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
